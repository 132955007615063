<template>
  <div class="main">
    <section row v-if="error">
      <div>
        <div class="app-alert" @dismissed="onDismissed" :text="error.message"></div>
      </div>
    </section>

    <section row>

      <form @submit.prevent="onSignin">
        <section row>
          <div >
            <input
              name="email"
              label="Email"
              id="email"
              placeholder="email"
              v-model="email"
              type="email"
              required>
          </div>
        </section>
        <section row>
          <div >
            <input
              name="password"
              label="Password"
              id="password"
              placeholder="password"
              v-model="password"
              type="password"
              required>
          </div>
        </section>

        <section row>

          <div class="text-xs-center">

            <button type="submit" :disabled="loading" :loading="loading">
              Sign in
              <span slot="loader" class="custom-loader">
                <!-- <div class="v-icon light">cached</div> -->
              </span>
            </button>

            <a href="#">Forgot password?</a>

            <!-- <button color="warning"  :disabled="loading" :loading="loading" @click.prevent="onResetPassword">Reset Password By Email
              <div class="v-icon right" dark>email</div>
              <span slot="loader" class="custom-loader">
              <div class="v-icon light">cached</div>
              </span>
            </button> -->

          </div>

          <div class="sign-in-options" style="display:none;">

            <button round color="red" dark :disabled="loading" :loading="loading" @click.prevent="onSigninGoogle">Login with Google
              <div class="v-icon right" dark>lock_open</div>
              <span slot="loader" class="custom-loader">
              <div class="v-icon light">cached</div>
              </span>
            </button>
  
            <button round color="primary" dark :disabled="loading" :loading="loading" @click.prevent="onSigninFacebook">Login with Facebook
              <div class="v-icon right" dark>lock_open</div>
              <span slot="loader" class="custom-loader">
              <div class="v-icon light">cached</div>
              </span>
            </button>

            <button round dark :disabled="loading" :loading="loading" @click.prevent="onSigninGithub">Login with Github
              <div class="v-icon right" dark>lock_open</div>
              <span slot="loader" class="custom-loader">
              <div class="v-icon light">cached</div>
              </span>
            </button>

            <button round color="info" dark :disabled="loading" :loading="loading" @click.prevent="onSigninTwitter">Login with Twitter
              <div class="v-icon right" dark>lock_open</div>
              <span slot="loader" class="custom-loader">
              <div class="v-icon light">cached</div>
              </span>
            </button>

            

          </div>
        </section>
      </form>

    </section>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        email: '',
        password: ''
      }
    },
    computed: {
      user () {
        return this.$store.getters.user
      },
      error () {
        return this.$store.getters.error
      },
      loading () {
        return this.$store.getters.loading
      }
    },
    watch: {
      user (value) {
        if (value !== null && value !== undefined) {
          this.$router.push('/')
        }
      }
    },
    methods: {
      onSignin () {
        this.$store.dispatch('signUserIn', {email: this.email, password: this.password})
      },
      onSigninGoogle () {
        this.$store.dispatch('signUserInGoogle')
      },
      onSigninFacebook () {
        this.$store.dispatch('signUserInFacebook')
      },
      onSigninGithub () {
        this.$store.dispatch('signUserInGithub')
      },
      onSigninTwitter () {
        this.$store.dispatch('signUserInTwitter')
      },
      onResetPassword () {
        if (this.email === '') {
          return this.$store.dispatch('setError', {message: 'Email can not be blank'})
        }
        this.$store.dispatch('resetPasswordWithEmail', {email: this.email})
      },
      onDismissed () {
        this.$store.dispatch('clearError')
      }
    }
  }
</script>

<style lang="scss" scoped>
.main {
  justify-content: center;
}
</style>